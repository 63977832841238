import { db } from "@/db/db";

import {
  account,
  broadcastAction,
  broadcastRecipient,
  directWsInvitation,
  feed,
  feedGroup,
  feedGroupMembership,
  feedPreference,
  item,
  permission,
  scheduleTrigger,
  template,
  workflowItem,
  workspace,
  workspaceCommandAlias,
  workspaceMembership,
} from "@/db/schema";
import { Account, WorkspaceMembership } from "@/db/types";
import { sq } from "date-fns/locale";
import {
  and,
  asc,
  desc,
  eq,
  getTableColumns,
  gte,
  ilike,
  isNotNull,
  isNull,
  or,
  sql,
} from "drizzle-orm";
import { NotificationFeedPreference } from "web-client";

const allEmpty = false;
const verbose = false;

function logQuery(name: string, query: any, ...args: any[]) {
  const s = query.toSQL();
  const sq = s.sql;
  const params = s.params;
  const emptyParams =
    params.filter((p) => p === undefined || p === null || p === "").length > 0;
  if (emptyParams) {
    console.error("Empty Params", { name, params, sq }, ...args);
  }
  if (verbose) {
    console.log("Common Queries", { name, params, sq }, ...args);
  }
}

export const emptyQuery = {
  toSQL: () => {
    return {
      sql: `select feed.id from feed where 1 = 0`,
      params: [],
    };
  },
  execute: () => Promise<[]>,
};

export function myWorkspaceMembershipsQuery({
  accountId,
  currentWorkspaceId,
}: { accountId?: string; currentWorkspaceId?: string }) {
  const query = db
    .select()
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, currentWorkspaceId),
        eq(workspaceMembership.accountId, accountId),
        eq(workspaceMembership.status, "active"),
      ),
    );

  if (!accountId || !currentWorkspaceId) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Workspace Memberships", query);
  return query;
}

export function groupsQuery({
  workspaceId,
  myCurrentWorkspaceMembershipId,
}: { workspaceId?: string; myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .selectDistinct({
      ...getTableColumns(feedGroup),
    })
    .from(feedGroup)
    .innerJoin(
      feedGroupMembership,
      eq(feedGroup.id, feedGroupMembership.groupId),
    )
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feedGroupMembership.feedId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
      ),
    )
    .where(eq(feedGroup.workspaceId, workspaceId));

  if (!workspaceId || !myCurrentWorkspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Groups", query);
  return query;
}

export function groupedFeedsQuery({
  workspaceId,
  myCurrentWorkspaceMembershipId,
}: {
  workspaceId?: string;
  myCurrentWorkspaceMembershipId?: string;
}) {
  const query = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      updatedAt: feed.updatedAt,
      groupId: feedGroupMembership.groupId,
    })
    .from(feed)
    .innerJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feed.id),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
      ),
    );
  if (!workspaceId || !myCurrentWorkspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Grouped Feeds", query);
  return query;
}

export function myActiveFeedsQuery({
  workspaceId,
  myCurrentWorkspaceMembershipId,
}: { workspaceId?: string; myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      updatedAt: feed.updatedAt,
      groupId: feedGroupMembership.groupId,
      // latestActivity: sql`GREATEST( coalesce(MAX(${item.createdAt}), '-infinity'::timestamp), coalesce(${feed.updatedAt}, '-infinity'::timestamp)) as "latestActivity"`,
      latestActivity: sql`COALESCE(${feed.latestActivity}, '1970-01-01T00:00:00.000Z'::timestamp) as "latestActivity"`,
      latestAliasActivity: sql`COALESCE(${feed.latestAliasActivity}, '1970-01-01T00:00:00.000Z'::timestamp) as "latestAliasActivity"`,
    })
    .from(feed)
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
        eq(permission.enabled, true),
        eq(permission.name, "read"),
      ),
    )
    // .leftJoin(item, eq(feed.id, item.feedId))
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId))
    .groupBy(
      feed.id,
      feed.title,
      feed.workspaceId,
      feed.updatedAt,
      feedGroupMembership.groupId,
      sql`"latestActivity"`,
      sql`"latestAliasActivity"`,
    )
    .where(and(eq(feed.workspaceId, workspaceId), eq(feed.isDm, false)))
    .orderBy(desc(sql`"latestAliasActivity"`), desc(sql`"latestActivity"`));

  if (!workspaceId || !myCurrentWorkspaceMembershipId) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Active Feeds", query);
  return query;
}

export function unreadFeedIdsQuery({
  myCurrentWorkspaceMembershipId,
}: { myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .selectDistinct({ feedId: item.feedId })
    .from(item)
    .innerJoin(feed, eq(feed.id, item.feedId))
    .innerJoin(
      permission,
      and(
        eq(permission.feedId, feed.id),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
      ),
    )
    .where(and(eq(item.unread, true), isNull(item.deletedAt)));
  if (!myCurrentWorkspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Unread Feed Ids", query);
  return query;
}

export function myFeedPermissionsQuery({
  currentFeedId,
  myCurrentWorkspaceMembershipId,
}: { currentFeedId?: string; myCurrentWorkspaceMembershipId?: string }) {
  const query = db
    .select()
    .from(permission)
    .where(
      and(
        eq(permission.feedId, currentFeedId),
        eq(permission.workspaceMembershipId, myCurrentWorkspaceMembershipId),
        eq(permission.enabled, true),
      ),
    );
  if (!currentFeedId || !myCurrentWorkspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("My Feed Permissions", query);
  return query;
}

export function feedPermissionsQuery({ feedId }: { feedId?: string }) {
  const query = db
    .select()
    .from(permission)
    .where(and(eq(permission.feedId, feedId)));

  if (!feedId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Feed Permissions", query);
  return query;
}

export function justWorkspaceMembershipsQuery({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .select()
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    );
  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Just Workspace Memberships", query);
  return query;
}

export function workspaceMembershipsQuery({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .select({
      accountId: workspaceMembership.accountId,
      role: workspaceMembership.role,
      membershipId: sql`${workspaceMembership.id} as membershipId`,
      email: sql`COALESCE(${account.email}, ${directWsInvitation.email}) as email`,
      phoneNumber: sql`COALESCE(${account.phoneNumber}, ${directWsInvitation.phoneNumber}) as "phoneNumber"`,
      name: sql`COALESCE(${account.name}, ${directWsInvitation.name}) as name`,
      avatarColor: account.avatarColor,
    })
    .from(workspaceMembership)
    .leftJoin(account, eq(workspaceMembership.accountId, account.id))
    .leftJoin(
      directWsInvitation,
      eq(workspaceMembership.id, directWsInvitation.workspaceMembershipId),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    );
  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Memberships", query);
  return query;
}

export function getChannelsWithPermissions({
  workspaceId,
  myMembership,
}: { workspaceId: string; myMembership: WorkspaceMembership }) {
  const query = db
    .select({
      ...getTableColumns(feed),
      permission_name: sql`${permission.name} as permission_name`,
      wsmid: sql`${permission.workspaceMembershipId} as wsmid`,
      name: account.name,
      email: account.email,
      // title: sql`IFNULL(${feed.title}, 'DM: ' || ${account.name})`,
    })
    .from(feed)
    .innerJoin(permission, eq(permission.feedId, feed.id))
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .innerJoin(account, eq(account.id, workspaceMembership.accountId))
    .where(
      and(
        eq(feed.workspaceId, workspaceId),
        eq(permission.name, "write"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, myMembership.id),
      ),
    )
    // .groupBy(feed.id)
    .orderBy(asc(feed.isDm), asc(feed.title));

  if (!workspaceId || !myMembership?.id || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Channels with Permissions", query);
  return query;
}

type ChannelItemResponse = {
  id: string;
  feedId: string;
  groupId: string;
  unread: boolean;
  title: string;
  feedGroupId: string;
  joined: string;
};

export const fetchAllGroupedChannelsQuery = async ({
  workspaceMembershipId,
  groupId,
}: {
  workspaceMembershipId: string;
  groupId: string;
}) => {
  const query = db
    .selectDistinct({
      id: feed.id,
    })
    .from(feed)
    .innerJoin(item, and(eq(feed.id, item.feedId), eq(item.unread, true)))
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .innerJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .where(eq(feedGroupMembership.groupId, groupId));

  if (!workspaceMembershipId || !groupId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Fetch all grouped channels", query);
  return query;
};

export const fetchAllGroupsWithJoinedStatusQuery = async ({
  workspaceMembershipId,
  groupId,
}: { workspaceMembershipId: string; groupId: string }) => {
  const query = db
    .select({
      id: feedGroupMembership.id,
      groupId: feedGroupMembership.groupId,
      joined: sql`${feedPreference.pushNotificationPreference} = 'all' as "joined"`,
      feedId: feedGroupMembership.feedId,
      title: feed.title,
    })
    .from(feedGroupMembership)
    .innerJoin(feed, eq(feed.id, feedGroupMembership.feedId))
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.name, "read"),
      ),
    )
    .leftJoin(
      feedPreference,
      and(
        eq(feed.id, feedPreference.feedId),
        eq(feedPreference.workspaceMembershipId, workspaceMembershipId),
      ),
    )
    .where(eq(feedGroupMembership.groupId, groupId))
    .groupBy(
      feedGroupMembership.id,
      feedGroupMembership.groupId,
      feedGroupMembership.feedId,
      feed.title,
      sql`"joined"`,
    );

  return query;
};

export const fetchAllNonGroupedChannelsQuery = async ({
  workspaceMembershipId,
}: { workspaceMembershipId: string }) => {
  const query = db
    .selectDistinct({
      id: feed.id,
    })
    .from(feed)
    .innerJoin(item, and(eq(feed.id, item.feedId), eq(item.unread, true)))
    .innerJoin(
      permission,
      and(
        eq(feed.id, permission.feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
      ),
    )
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .where(isNull(feedGroupMembership.groupId));
  if (!workspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Fetch all non grouped channels", query);
  return query;
};

export type ChannelOrderType = "driver-activity" | "alpha-asc";
export type ChannelTypeType =
  | "my-channels"
  | "my-unreads"
  | "all-channels"
  | "all-unreads";

export type ChannelList = {
  id: string;
  title: string;
  workspaceId: string;
  isPrivate: boolean;
  enabled: boolean;
  groupId: string;
  groupName: string;
  joined: boolean;
  unread: boolean;
  aliasChannel: boolean;

  latestActivity?: string;
  driverActivity?: string;
  unifiedTimestamp?: string;
  feedLatestActivity?: string;
  feedLatestAliasActivity?: string;
};

export const fetchChannelList = ({
  channelOrder,
  channelType,
  workspaceId,
  searchValue,
  myAccount,
  myCurrentWorkspaceMembership,
}: {
  channelOrder: ChannelOrderType;
  channelType: ChannelTypeType;
  workspaceId: string;
  searchValue: string;
  myCurrentWorkspaceMembership: WorkspaceMembership;
  myAccount: Account;
}) => {
  const workspaceMembershipId = myCurrentWorkspaceMembership?.id;
  const notDM = eq(feed.isDm, false);
  const titleNotNull = isNotNull(feed.title);

  const whereClause = [notDM, titleNotNull];
  if (searchValue?.length > 0) {
    whereClause.push(
      or(
        ilike(feed.title, `%${searchValue}%`),
        ilike(feedGroup.name, `%${searchValue}%`),
      ),
    );
  }

  const driverActivity = sql`
  COALESCE(
    MAX(${item.createdAt}) FILTER(where ${item.isFromAliasMember} = true),
    '-infinity'::timestamp
   )`;
  const latestActivity = sql`GREATEST(${feed.updatedAt} , MAX(${item.createdAt}))`;

  const unifiedTimestamp = sql`
      CASE 
        WHEN BOOL_OR(${feed.isAliasFeed}) IS FALSE THEN ${latestActivity}
        ELSE ${driverActivity}
      END
  `;

  let scratch = db
    .select({
      id: feed.id,
      title: feed.title,
      workspaceId: feed.workspaceId,
      isPrivate: feed.isPrivate,
      joined: sql`${feedPreference.pushNotificationPreference} = 'all' as "joined"`,
      enabled: permission.enabled,
      groupId: feedGroupMembership.groupId,
      groupName: sql`${feedGroup.name} as "groupName"`,
      aliasChannel: sql`${feed.isAliasFeed} as "aliasChannel"`,
      feedLatestActivity: sql`${feed.latestActivity} as "feedLatestActivity"`,
      feedLatestAliasActivity: sql`${feed.latestAliasActivity} as "feedLatestAliasActivity"`,

      unifiedTimestamp: sql`${unifiedTimestamp} as "unifiedTimestamp"`,
      driverActivity: driverActivity.as("driverActivity"),
      latestActivity: latestActivity.as("latestActivity"),
      unread: sql`MAX(${item.createdAt}) FILTER(where ${item.unread} = true) as unread`,
    })
    .from(feed);

  scratch.innerJoin(
    permission,
    and(
      eq(feed.id, permission.feedId),
      eq(permission.workspaceMembershipId, workspaceMembershipId),
      eq(permission.name, "read"),
      eq(permission.enabled, true),
    ),
  );

  switch (channelType) {
    case "my-channels":
      scratch.leftJoin(
        item,
        and(eq(feed.id, item.feedId), isNull(item.deletedAt)),
      );
      scratch.innerJoin(
        feedPreference,
        and(
          eq(feed.id, feedPreference.feedId),
          eq(feedPreference.workspaceMembershipId, workspaceMembershipId),
          eq(feedPreference.pushNotificationPreference, "all"),
        ),
      );
      break;
    case "my-unreads":
      scratch.innerJoin(
        item,
        and(eq(feed.id, item.feedId), eq(item.unread, true)),
      );
      scratch.innerJoin(
        feedPreference,
        and(
          eq(feed.id, feedPreference.feedId),
          eq(feedPreference.workspaceMembershipId, workspaceMembershipId),
          eq(feedPreference.pushNotificationPreference, "all"),
        ),
      );
      break;
    case "all-channels":
      scratch.leftJoin(
        item,
        and(eq(feed.id, item.feedId), isNull(item.deletedAt)),
      );
      scratch.leftJoin(
        feedPreference,
        and(
          eq(feed.id, feedPreference.feedId),
          eq(feedPreference.workspaceMembershipId, workspaceMembershipId),
        ),
      );
      break;
  }
  scratch
    .leftJoin(feedGroupMembership, eq(feed.id, feedGroupMembership.feedId))
    .leftJoin(feedGroup, eq(feedGroup.id, feedGroupMembership.groupId));

  scratch.where(and(...whereClause)).groupBy(
    feed.id,
    feed.title,
    feed.workspaceId,
    feed.isPrivate,
    sql`joined`,
    permission.enabled,
    feedGroupMembership.groupId,
    sql`"groupName"`,
    sql`"aliasChannel"`,
    sql`"feedLatestActivity"`,
    sql`"feedLatestAliasActivity"`,
    // sql`"unifiedTimestamp"`,
  );

  // nulls last: https://orm.drizzle.team/docs/select#order-by
  if (channelOrder === "driver-activity") {
    scratch.orderBy(desc(sql`"unifiedTimestamp"`));
  } else {
    scratch.orderBy(asc(sql`lower(${feed.title})`));
  }

  if (!workspaceId || !workspaceMembershipId || !myAccount?.id || allEmpty) {
    return emptyQuery as unknown as typeof scratch;
  }
  logQuery("Fetch channel list", scratch);
  return scratch;
};

export function findWorkspaceQuery({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      name: workspace.name,
    })
    .from(workspace)
    .where(and(eq(workspace.id, workspaceId)));

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Find Workspace", query);
  return query;
}

export function currentFeedQuery({
  currentFeedId,
}: { currentFeedId?: string }) {
  const query = db.select().from(feed).where(eq(feed.id, currentFeedId));

  if (!currentFeedId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Current Feed", query);
  return query;
}

export function currentFeedPendingInvitesSelect({
  currentFeedId,
}: {
  currentFeedId?: string;
}) {
  const query = db
    .select({
      ...getTableColumns(directWsInvitation),
    })
    .from(directWsInvitation)
    .innerJoin(
      permission,
      eq(
        directWsInvitation.workspaceMembershipId,
        permission.workspaceMembershipId,
      ),
    )
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .where(
      and(
        eq(permission.feedId, currentFeedId),
        eq(permission.name, "read"),
        eq(permission.enabled, true),
        isNull(directWsInvitation.claimedBy),
        eq(workspaceMembership.status, "active"),
      ),
    );

  if (!currentFeedId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Current Feed Pending Invites", query);
  return query;
}

export function workflowItemsSelect({ workspaceId }: { workspaceId?: string }) {
  const query = db
    .selectDistinct({
      ...getTableColumns(workflowItem),
    })
    .from(workflowItem)
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
      ),
    )
    .orderBy(desc(workflowItem.createdAt));

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workflow Items", query);
  return query;
}

export function broadcastActionSelect({
  workspaceId,
}: { workspaceId?: string }) {
  const query = db
    .selectDistinct({
      ...getTableColumns(broadcastAction),
    })
    .from(broadcastAction)
    .innerJoin(
      workflowItem,
      eq(broadcastAction.workflowItemId, workflowItem.id),
    )
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
      ),
    );

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Broadcasts", query);
  return query;
}

export function scheduleTriggerSelect({
  workspaceId,
  date,
}: {
  workspaceId?: string;
  date?: string;
}) {
  const query = db
    .selectDistinct({
      ...getTableColumns(scheduleTrigger),
    })
    .from(scheduleTrigger)
    .innerJoin(
      broadcastAction,
      eq(scheduleTrigger.broadcastActionId, broadcastAction.id),
    )
    .innerJoin(
      workflowItem,
      eq(broadcastAction.workflowItemId, workflowItem.id),
    )
    .where(
      and(
        eq(workflowItem.workspaceId, workspaceId),
        isNull(workflowItem.deletedAt),
        or(
          gte(scheduleTrigger.oneTimeSchedule, date),
          isNotNull(scheduleTrigger.cronSchedule),
        ),
      ),
    );

  if (!workspaceId || !date || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Schedule Triggers", query);
  return query;
}

export function workspaceMemberCountResponseSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      memberCount: sql`COUNT(${workspaceMembership.accountId}) as "memberCount"`,
    })
    .from(workspaceMembership)
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNotNull(workspaceMembership.accountId),
      ),
    );

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Member Count", query);
  return query;
}

export function workspaceInviteCountResponseSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      inviteCount: sql`COUNT(${directWsInvitation.id}) as "inviteCount"`,
    })
    .from(directWsInvitation)
    .innerJoin(
      workspaceMembership,
      eq(directWsInvitation.workspaceMembershipId, workspaceMembership.id),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNull(directWsInvitation.claimedBy),
      ),
    );

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Invite Count", query);
  return query;
}

export function workspaceInviteTableSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      ...getTableColumns(directWsInvitation),
      contact: sql`COALESCE(${directWsInvitation.email}, ${directWsInvitation.phoneNumber}) as contact`,
      date: sql`${directWsInvitation.createdAt} as date`,
      membershipStatus: sql`${workspaceMembership.status} as "membershipStatus"`,
      role: sql`${workspaceMembership.role} as "role"`,
    })
    .from(directWsInvitation)
    .innerJoin(
      workspaceMembership,
      eq(directWsInvitation.workspaceMembershipId, workspaceMembership.id),
    )
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
        isNull(directWsInvitation.claimedBy),
      ),
    )
    .orderBy(desc(directWsInvitation.createdAt));

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Invites", query);
  return query;
}

export function workspaceMembersTableSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select({
      id: account.id,
      email: account.email,
      phoneNumber: account.phoneNumber,
      name: account.name,
      mine: account.mine,

      role: workspaceMembership.role,
      membershipId: sql`${workspaceMembership.id} as "membershipId"`,
    })
    .from(workspaceMembership)
    .innerJoin(account, eq(workspaceMembership.accountId, account.id))
    .where(
      and(
        eq(workspaceMembership.workspaceId, workspaceId),
        eq(workspaceMembership.status, "active"),
      ),
    )
    .orderBy(asc(account.name));

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Workspace Members", query);
  return query;
}

export function cachedTemplatesSelect({
  workspaceId,
}: {
  workspaceId?: string;
}) {
  const query = db
    .select()
    .from(template)
    .where(
      and(eq(template.workspaceId, workspaceId), isNull(template.deletedAt)),
    );

  if (!workspaceId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Cached Templates", query);
  return query;
}

export function broadcastRecipientsSelect({
  broadcastId,
}: {
  broadcastId?: string;
}) {
  const query = db
    .select()
    .from(broadcastRecipient)
    .where(eq(broadcastRecipient.broadcastId, broadcastId));

  if (!broadcastId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Broadcast Recipients", query);
  return query;
}

export function channelsWithPermissionsSelect({
  workspaceId,
  workspaceMembershipId,
}: { workspaceId?: string; workspaceMembershipId?: string }) {
  const query = db
    .select({
      ...getTableColumns(feed),
      permission_name: sql`${permission.name} as permission_name`,
      wsmid: sql`${permission.workspaceMembershipId} as wsmid`,
      name: account.name,
      email: account.email,
    })
    .from(feed)
    .innerJoin(permission, eq(permission.feedId, feed.id))
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, permission.workspaceMembershipId),
    )
    .innerJoin(account, eq(account.id, workspaceMembership.accountId))
    .where(
      and(
        eq(feed.workspaceId, workspaceId),
        eq(permission.name, "write"),
        eq(permission.enabled, true),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
      ),
    )
    // .groupBy(feed.id)
    .orderBy(asc(feed.isDm), asc(feed.title));

  if (!workspaceId || !workspaceMembershipId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Channels with Permissions", query);
  return query;
}

export const fetchFeedCommandAliasesQuery = (feedId: string) => {
  const query = db
    .select({
      id: account.id,
      name: account.name,
      alias: workspaceCommandAlias.alias,
      title: feed.title,
    })
    .from(workspaceCommandAlias)
    .innerJoin(
      workspaceMembership,
      eq(workspaceMembership.id, workspaceCommandAlias.workspaceMembershipId),
    )
    .innerJoin(account, eq(account.id, workspaceMembership.accountId))
    .innerJoin(feed, eq(feed.id, feedId))
    .where(eq(workspaceCommandAlias.feedId, feedId))
    .limit(1);

  if (!feedId || allEmpty) {
    return emptyQuery as unknown as typeof query;
  }
  logQuery("Channels with Permissions", query);
  return query;
};
